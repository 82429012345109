import React from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import Loader from './shared/Loader'

// Layouts
import Layout2 from "./shared/layouts/Layout2";
import helpers from "./shared/layouts/helpers";
import Layout1 from "./shared/layouts/Layout1";
import Layout1Flex from "./shared/layouts/Layout1Flex";
import LayoutWithoutNavbar from "./shared/layouts/LayoutWithoutNavbar";
import LayoutWithoutSidenav from "./shared/layouts/LayoutWithoutSidenav";

// Lazy load component
const lazy = (cb) => loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> });

// ---
// Default application layout

export const DefaultLayout = LayoutWithoutSidenav;

// ---
// Document title template

export const titleTemplate = '%s - ' + helpers.getSystem();

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = '/';
export const routes = [
  {
    path: '/create/repair/:company?',
    component: lazy(() => import('./components/CreateRepair')),
  },
  {
    path: '/reservation',
    component: lazy(() => import('./components/Reservation')),
  },
  {
    path: '/account/:tab?',
    component: lazy(() => import('./components/Account')),
  },
  {
    path: '/repairs/guid/:guid/:route?',
    component: lazy(() => import('./components/ProjectGUID')),
  },
  {
    path: '/repairs/:id',
    component: lazy(() => import('./components/Project')),
  },
  {
    path: '/login',
    component: lazy(() => import('./components/Login')),
  },
  {
    path: '/contact',
    component: lazy(() => import('./components/Contact')),
  },
  {
    path: '/damage/prevention',
    component: lazy(() => import('./components/DamagePrevention')),
  },
  {
    path: '/claim/:guid?',
    component: lazy(() => import('./components/CreateClaim')),
  },
  {
    path: '/offer/:guid',
    component: lazy(() => import('./components/Offer')),
  },
  {
    path: '/digital/service/:id?',
    component: lazy(() => import('./components/DigitalService')),
  },
  {
    path: '/',
    component: lazy(() => import('./components/Dashboard')),
  },
];
