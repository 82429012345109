import React, { Component } from 'react'
import {Form, Button, Row, Col, Card, Badge} from 'react-bootstrap'
import axios from "axios";
import Loader from "../shared/Loader";
import helpers from "../shared/layouts/helpers";
import Swal from "sweetalert2";
import AuthService from '../services/auth-service';
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import BingTracking from "react-tracking-bing";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        //document.body.style.background = "#243449";
        if (!AuthService.getCurrentUser() && (window.location.pathname).length > 1) {
           window.location = "/";
        }
        document.title = "Service Portal | " + helpers.getSystem()
        this.state = {
            types: [],
            username: "",
            password: "",
            rememberMe: "",
            loading: false,
            error: false,
            host: window.location.hostname.includes("refurbee") ? "refurbee" : "mentech",
        };

        ReactGA.send('pageview', window.location.pathname);
        ReactPixel.pageView();
        BingTracking.trackPageview();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true, error: false});

        AuthService.login(this.state.username, this.state.password, this.state.country).then(
            () => {
                window.location.reload();
            },
            error => {
                Swal.fire(
                    this.props.t("Invalid credentials!"),
                    this.props.t("Please check your username and password"),
                    'error',
                );

                this.setState({
                    loading: false,
                });
            }
        );
    };

    componentDidMount() {
        helpers.getAPI().then((api => {
            axios.get(api + (helpers.getUser() ? '/nsp/account/get/footer' : '/nsp/get/footer')).then(res => {
                this.setState({...res.data});
            })

            if (helpers.getUser()) {
                axios.get(api + '/nsp/account/damage/prevention/articles').then((response) => {
                    this.setState({
                        types: response.data.types
                    });
                })
            }
        }))


    }

    render() {
        return (
            <div className="">
                {this.state.loading && <Loader/>}
                <Row className={"mt-4"}>
                    <Col md={3}  className={"mb-2"}>
                        <Card bg={"dark"} className="mb-1" style={{height: '300px', margin: '0 auto', overflow: 'hidden', position: 'relative'}}>
                            <Card.Img src={process.env.PUBLIC_URL + '/img/dashboard/1.jpg'}  style={{ position: 'absolute', width: '100%'}}/>
                            <Card.ImgOverlay className={"d-flex align-items-center"}>
                                <div>
                                    <div className="text-large font-weight-bolder text-white">{this.props.t('Order service')}</div>
                                    <p className={"text-big text-white"}>{this.props.t('Create your repairs and service requests')}</p>
                                    <div className={"text-left"}>
                                        <Link to={"/create/repair"} className={"btn btn-white"}>{this.props.t("Order service")}</Link>
                                    </div>
                                </div>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col md={3}  className={"mb-2"}>
                        {!helpers.getUser() && <Card className="mb-1" style={{height: '300px', margin: '0 auto', overflow: 'hidden', position: 'relative'}}>
                            <Card.Img src={process.env.PUBLIC_URL + '/img/dashboard/2.jpg'}  style={{ position: 'absolute', width: '100%'}}/>
                            <Card.ImgOverlay className={"d-flex align-items-center"}>
                                <div>
                                    <div className="text-large font-weight-bolder text-white">{this.props.t('Sign in')}</div>
                                    <p className={"text-big text-white"}>{this.props.t('View settings & account details')}</p>
                                    <div className={"text-left"}>
                                        <Link to={"/login"} className={"btn btn-white"}>{this.props.t("Sign in")}</Link>
                                    </div>
                                </div>
                            </Card.ImgOverlay>
                        </Card>}

                        {helpers.getUser() && <Card className="mb-1" style={{height: '300px', margin: '0 auto', overflow: 'hidden', position: 'relative'}}>
                            <Card.Img src={process.env.PUBLIC_URL + '/img/dashboard/2.jpg'}  style={{ position: 'absolute', width: '100%'}}/>

                            <Card.ImgOverlay className={"d-flex align-items-center"}>
                                <div>
                                    <div className="text-large font-weight-bolder text-white">{this.props.t('My profile')}</div>
                                    <p className={"text-big text-white"}>{this.props.t('Settings & account details')}</p>
                                    <div className={"text-left"}>
                                        <Link to={"/account"} className={"btn btn-white"}>{this.props.t("Show account")}</Link>
                                    </div>
                                </div>
                            </Card.ImgOverlay>
                        </Card>}
                    </Col>


                    <Col md={3}  className={"mb-2"}>
                        <Card bg={"dark"} className="mb-1" style={{height: '300px', margin: '0 auto', overflow: 'hidden', position: 'relative'}}>
                            <Card.Img src={process.env.PUBLIC_URL + '/img/dashboard/4.jpg'}  style={{ position: 'absolute', width: '100%'}}/>
                            <Card.ImgOverlay className={"d-flex align-items-center"}>
                                <div>
                                    <div className="text-large font-weight-bolder text-white">{this.props.t('Make a reservation')}</div>
                                    <p className={"text-big text-white"}>{this.props.t('Make a reservation, and plan your visit.')}</p>
                                    <div className={"text-left"}>
                                        <Link to={"/reservation"} className={"btn btn-white"}>{this.props.t("Book now")}</Link>
                                    </div>
                                </div>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col md={3}  className={"mb-2"}>
                        <Card bg={"dark"} className="mb-1" style={{height: '300px', margin: '0 auto', overflow: 'hidden', position: 'relative'}}>
                            <Card.Img src={process.env.PUBLIC_URL + '/img/dashboard/3.jpg'}  style={{ position: 'absolute', width: '100%'}}/>
                            <Card.ImgOverlay className={"d-flex align-items-center"}>
                                <div>
                                    <div className="text-large font-weight-bolder text-white">{this.props.t('Need help?')}</div>
                                    <p className={"text-big text-white"}>{this.props.t("We're only a text or a call away.")}</p>
                                    <div>
                                        <Badge variant={"primary"} style={{borderRadius: '12px'}} className={"p-3"}>
                                            <i className={"fa fa-sms"}></i> {this.props.t('Text us')} {this.state.sms}
                                        </Badge>
                                        <br/>
                                        <Badge variant={"primary"} style={{borderRadius: '12px'}} className={"mt-2 p-3"} >
                                            <i className={"fa fa-phone-alt"}></i> {this.props.t('Call us')} {this.state.phone}
                                        </Badge>
                                    </div>
                                </div>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    {this.state.types && this.state.types.length > 10 && <Col md={3}  className={"mb-2"}>
                        <Card bg={"dark"} className="mb-1" style={{height: '300px', margin: '0 auto', overflow: 'hidden', position: 'relative'}}>
                            <Card.Img src={process.env.PUBLIC_URL + '/img/dashboard/4.jpg'}  style={{ position: 'absolute', width: '100%'}}/>
                            <Card.ImgOverlay className={"d-flex align-items-center"}>
                                <div>
                                    <div className="text-large font-weight-bolder text-white">{this.props.t('Damage prevention')}</div>
                                    <p className={"text-big text-white"}>{this.props.t('Read our recommendations')}</p>
                                    <div className={"text-left"}>
                                        <Link to={"/damage/prevention"} className={"btn btn-white"}>{this.props.t("Read more")}</Link>
                                    </div>
                                </div>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>}
                </Row>

            </div>
        )
    }
}

export default withTranslation()(Dashboard);
